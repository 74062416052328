<template>
  <div v-if="!loaded">
    <i class="fa-3x fas fa-spinner fa-pulse"></i>
  </div>
  <div v-else class="buttons">
    <button class="btn pointer" :class="{language : languages.length > 6}" v-for="language in languages"
            :key="language.id" @click="goToNextScreen(language)"
            :disabled="disableButtons">
      {{ language.name }}
    </button>
  </div>
</template>

<script>
import screenApiService from "@/services/screenApiService";
import screenService from "@/services/screenApiService";
import helper from "@/services/helper";
import store from "@/store";
import {mapState} from "vuex";

export default {
  name: "Languages",
  data() {
    return {
      languages: [],
      buttons: [],
      loaded: false
    }
  },
  mounted() {
    this.getLanguages();
    this.buttons = store.state.currentScreenOptions;
    screenApiService.showPopup();
  },
  beforeUnmount() {
    screenApiService.resetPopup();
  },
  computed: mapState(['disableButtons']),
  methods: {
    getLanguages() {
      screenApiService.getLanguages().then((response) => {
        this.languages = response.data;
        helper.sortByKey(this.languages, 'sortOrder');
        this.loaded = true;
      }).catch((er) => {
        console.log(er);
      })
    },
    goToNextScreen(language) {
      store.state.disableButtons = true;
      let languageButton = this.buttons[0];
      languageButton.languageChosen = language;
      store.commit('selectLanguage', language.id)
      let screenshot = {
        selectedButton: languageButton,
        flowOptions: store.state.currentScreenOptions,
        screenProperties: store.state.currentScreenProperties,
        languageId: store.state.languageId,
        domaintypeId: store.state.domaintypeId
      };
      screenService.goToNextScreen(screenshot);
    }
  }
}
</script>