<template>
  <div class="instruction">
    <span v-if="JSON.stringify(visitor) !== '{}'">
      {{ visitor.FIRSTNAMES + ' ' + visitor.SURNAME }} <br>
      {{ visitor.DATE_OF_BIRTH }}
    </span>
  </div>

  <QppButton :buttons="currentScreenOptions"></QppButton>
</template>

<script>
import screenApiService from "@/services/screenApiService";
import QppButton from "@/components/common/QppButton";
import {mapState} from "vuex";


export default {
  name: 'EID_DISPLAY',
  components: {QppButton},
  computed:
      mapState(['visitor', 'currentScreenOptions'])
  ,
  mounted() {
    screenApiService.showPopup();
  },
  beforeUnmount() {
    screenApiService.resetPopup();
  }
}
</script>
