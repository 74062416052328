<template>
  <div class="heading" :class="logoPath==='' ? 'w-full' : 'w-88'"
       v-if="currentRouteMeta.header===true && (currentScreenOptions.length>1 || currentScreenProperties.type==='VISITOR_VALIDATION' || currentScreenProperties.type==='INPUT_FORM')">
    <DisplayText :screen-properties="currentScreenProperties"></DisplayText>
  </div>

  <img v-if="showLogo && logoPath!==''" :src="logoPath" class="header-toggle-image" alt="header" @click="toggleText"/>
  <i v-if="!showLogo" class="fa fa-5x fa-language header-toggle-image secondary-color" title="Vertalen" @click="toggleText"/><br>


</template>

<script>
import {mapState} from "vuex";
import DisplayText from "@/components/common/DisplayText";
import store from "@/store";

// const logo = require("@/assets/images/translateToggle.png");

export default {
  name: "Header",
  components: {DisplayText},
  computed: {
    ...mapState(['currentScreenProperties', 'currentScreenOptions', 'siteTheme']),
    currentRouteMeta() {
      return this.$route.meta;
    }
  },
  data() {
    return {
      logoPath: '',
      showLogo: false
    }
  },
  mounted() {
    this.getLogo();
  },
  methods: {
    toggleText() {
      store.commit('toggleText');
    },
    getLogo() {
      if (this.siteTheme && this.siteTheme.kioskHeaderToggleLogo === '') {
	      this.showLogo  = true;
	      this.logoPath = '';
      } else if (this.siteTheme && this.siteTheme.kioskHeaderToggleLogo === undefined) {
	      this.showLogo = false;
      } else {
        this.showLogo = true;
        this.logoPath = this.siteTheme.kioskHeaderToggleLogo;
      }
    }
  }
}
</script>