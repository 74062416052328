<template>
  <div v-if="isInstruction">
    <span v-if="toggleText" v-html="instructions.walkingInstructionHelp"></span>
    <span v-else v-html="instructions.walkingInstruction"></span>
  </div>
  <div v-else>
    <span v-if="toggleText" v-html="screenProperties.helpText"></span>
    <span v-else v-html="screenProperties.text"></span>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DisplayText",
  props: ['screenProperties', 'isInstruction', 'instructions'],
  computed: mapState(['toggleText'])
}
</script>