<template>
  <!--PRINT-->
  <div className="instruction" v-if="loaded">
    <div v-if="instructions.appointmentText">
      <br>
      <span v-if="toggleText" v-html="instructions.appointmentTextHelp"></span>
      <span v-else v-html="instructions.appointmentText"></span>
      <br><br><br>
      <hr>
      <br>
    </div>

    <DisplayText :screenProperties="screenProperties" :isInstruction="true" :instructions="instructions"></DisplayText>

    <br><br><br>

    <!--Arrow-->
    <span className="route">{{ instructions.route }}</span>
    <svg v-if="instructions && instructions.arrowDegrees"
         id="directionArrow"
         width="10vw"
         height="10vw"
         viewBox="0 0 24 24"
         :fill="'#'+instructions.routeColor" stroke="none"
         :transform="'rotate(' + instructions.arrowDegrees + ')'">
      <path
          d="M 7,11 H 1 L 12,0 23,11 H 17 V 24 H 7 Z"/>
    </svg>
  </div>

  <button className="btn buttons pointer" @click="closeInstruction({'id':'closeInstruction'})">{{ close }}</button>
</template>

<script>
import store from "@/store";
import screenService from "@/services/screenApiService";
import DisplayText from "@/components/common/DisplayText";
import screenApiService from "@/services/screenApiService";
import zijderoute from "@/services/zijderoute";
import {mapState} from "vuex";

export default {
  name: 'Instruction',
  components: {DisplayText},
  data() {
    return {
      instructions: {},
      screenProperties: {},
      loaded: false,
      close: ''
    }
  },
  computed: mapState(['toggleText']),
  mounted() {
    this.screenProperties = store.state.currentScreenProperties;
    this.instructions = store.state.currentScreenInstructions;
    this.getTranslation(store.state.languageId, 'close');
    this.loaded = true;
    screenApiService.showPopup();
    this.printTicket();
  },
  beforeUnmount() {
    screenApiService.resetPopup();
  },
  methods: {
    getTranslation(lang = store.state.languageId, key) {
      let app = this;
      if (lang) {
        screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
          app[key] = response.data;
        }).catch(function (er) {
          console.log("No translation found for fe." + key + " in language " + lang);
          console.log(er);
          app[key] ="-";
        })
      }
    },
    printTicket() {
      // TO DO: check printer status first

      zijderoute.print({}, (msg) => {
        console.log(msg);
      });
    },
    closeInstruction(button) {
      let screenshot = {
        selectedButton: button,
        flowOptions: store.state.currentScreenOptions,
        screenProperties: store.state.currentScreenProperties,
        instructions: store.state.currentScreenInstructions,
        languageId: store.state.languageId,
        domaintypeId: store.state.domaintypeId,
      };
      screenService.endFlow(screenshot);
    }
  }
}
</script>
