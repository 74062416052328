<template>
	<div v-if="!isLoaded">
		<i class="fa-3x fas fa-spinner fa-pulse"></i>
	</div>

	<div v-else>
		<div class="instruction">
      <span class="heading w3-margin-bottom">
        <i class="fas fa-info-circle"></i> {{ fieldName }}
      </span>
		</div>

		<div class="manual-entry w3-margin-top">
			<div class="input-field">
				<input :value="input" :id="text_field + 'manual_input'" class="input" @input="onInputChange" placeholder="" autocomplete="off" autofocus="autofocus" type="text" :title="fieldName" spellcheck="false">
				<div v-if="invalidInput" class="error-text">{{ invalidValueInfo }}</div>
			</div>
			<SimpleKeyboard customLayout="numeric" @onChange="onChange" @onKeyPress="onKeyPress" :input="input" :keyboardClass="text_field+'-keyboard'"/>
		</div>
		<div class="buttons">
			<button class="btn validation-form-submit" @click="submitForm()">{{ submit }}</button>
      <button v-if="skippable" class="btn validation-form-submit" @click="skipForm()">{{ skip }}</button>
		</div>
	</div>
</template>

<script>

import store from "@/store";
import SimpleKeyboard from "@/components/common/SimpleKeyboard";
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";

const toTranslate = ['invalidValueInfo', 'submit', 'fieldName', 'skip']


export default {
	name: "NUMERIC_FORM",
	components: {SimpleKeyboard},
  props: {
    'text_field': undefined, 'text_value': undefined, 'type': {
      required: false
    }
  },
	emits: ['dataSaved'],
	data() {
		return {
			screenProperties: {},
			buttons: [],
			input: "",
			inputSaved: false,
			fieldName: '',
			isLoaded: false,
			invalidInput: false,
			invalidValueInfo: 'Gelieve geldige gegevens in te geven',
      skip: 'Skip',
			submit: '',
      validateFields: ['phone', 'telephone', 'gsm']
		}
	},
  computed: {
    skippable() {
      if (this.type === 'input') {
        return false;
      }

      const allowSkipValidation = this.siteTheme.allowSkipValidation;

      const skipSettings = allowSkipValidation ? allowSkipValidation.toLowerCase() !== 'false' : true;

      return skipSettings;
    },

    ...mapState(['toggleText', 'siteTheme'])
  },
	watch: {
		toggleText(newValue) {
			if (store.state.languageId !== store.state.defaultLanguage.id) {
				if (newValue === true) {
					this.getTranslation(store.state.defaultLanguage.id, toTranslate);
				} else if (newValue === false) {
					this.getTranslation(store.state.languageId, toTranslate);
				}
			}
		}
	},
	mounted() {
		this.buttons = store.state.currentScreenOptions;
		this.screenProperties = store.state.currentScreenProperties;
		this.getTranslation(store.state.languageId, toTranslate);
		this.isLoaded = true;
		this.input = this.text_value;
		this.fieldName = this.text_field;
	},
	methods: {
		getTranslation(lang = store.state.languageId, keys) {
			let app = this;
			if (lang) {
				keys.forEach(function (key) {
					if (key === 'fieldName') {
						screenService.getTranslation(lang, 'fe.' + app.text_field).then(function (response) {
							app['fieldName'] = response.data;
						}).catch(function () {
							console.log("No translation found for fe." + key + " in language " + lang);
							app[key] = '-'
						})
					} else {
						screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
							app[key] = response.data;
						}).catch(function () {
							console.log("No translation found for fe." + key + " in language " + lang);
							app[key] = '-'
						})
					}
				})
			}
		},
		onChange(input) {
			this.input = input;
		},
		onKeyPress() {
			// console.log("button", button);
		},
		onInputChange(input) {
			this.input = input.target.value;
			this.invalidInput = false;
		},
		validatePhone(value) {
			const re = /^\+?\d{9,13}$/;
			return re.test(value) || !this.validateFields.includes(this.text_field);
		},
		stripSpecialCharacters(inputString) {
			let i = 0;
			while (inputString[i] === ' ') {
				i++;
			}

			// Remove all starting spaces
			let noStartingSpaceString = inputString.substring(i).replace(' ', '');

			// Define regular expression pattern to match non-numeric characters
			let pattern = /[^0-9]/g; // Matches any character that is not a digit

			// Remove all non-numeric characters
			let strippedString = noStartingSpaceString.replace(pattern, '');

			// Retain the "+" character at the start of the string
			if (strippedString.length > 0 && noStartingSpaceString[0] === '+') {
				strippedString = '+' + strippedString;
			}

			return strippedString;
		},
		submitForm() {
			let app = this;
			let phoneGiven = app.input;
			let formattedPhone = app.stripSpecialCharacters(phoneGiven)
			console.log(`Formatted number is: ${formattedPhone}`)

			if (app.validatePhone(formattedPhone)) {
				app.inputSaved = true;
				app.$emit('dataSaved', {'field': app.text_field, 'value': formattedPhone})
			} else {
				app.invalidInput = true;
				app.isLoaded = true;
			}
		},
    skipForm() {
      this.inputSaved = true;
      this.$emit('dataSaved', {'field': this.text_field, 'value': this.text_value})
    }
	}
}
</script>

<style scoped>

</style>