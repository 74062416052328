import store from "@/store";

const scanImage = require("@/assets/images/scan.png");

export default {
    sortByKey(array, key) {
        if (array && array.length > 1) {
            array.sort(function (a, b) {
                return a[key] - b[key];
            });
        }
    },
    async findImage(imageName) {
        let imageFound = false;
        let imgUrl;
        if (store.state.siteTheme && store.state.siteTheme.mediaFilesBaseURL) {
            imgUrl = store.state.siteTheme.mediaFilesBaseURL;

            if (imgUrl.length > 0 && imgUrl.substring(imgUrl.length - 1) !== '/') {
                imgUrl += '/'
            }
            await this.imageExists(imgUrl + imageName).then(function (res) {
                console.log(imgUrl + imageName + " found with height " + res)
                imageFound = true;
            }).catch(function () {
                console.log(imgUrl + imageName + " not found")
                imageFound = false;
            });
        }
        return imageFound ? imgUrl + imageName : scanImage;
    },
    imageExists(src) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(img.height)
            img.onerror = reject
            img.src = src
        })
    }
};