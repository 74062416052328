import {createRouter, createWebHistory} from 'vue-router';
import Layout from './components/Layout.vue';
import START from './components/screenTypes/START.vue';
import SELECT_AND_NAVIGATE from './components/screenTypes/SELECT_AND_NAVIGATE.vue';
import START_EID from "./components/screenTypes/START_EID";
import INSTRUCTION from "@/components/screenTypes/INSTRUCTION";
import SELECT_LANGUAGE from "@/components/screenTypes/SELECT_LANGUAGE";
import EID_DISPLAY from "@/components/screenTypes/EID_DISPLAY";
import VISITOR_VALIDATION from "@/components/screenTypes/VISITOR_VALIDATION";
import EID_READ from "@/components/screenTypes/EID_READ";
import INPUT_FORM from "@/components/screenTypes/INPUT_FORM";
import INPUT_FORM_DISPLAY from "@/components/screenTypes/INPUT_FORM_DISPLAY";
import PRINT from "@/components/screenTypes/PRINT";
import QR_DISPLAY from "@/components/screenTypes/QR_DISPLAY";
import QR_READ from "@/components/screenTypes/QR_READ";
import BADGE_READ from "@/components/screenTypes/BADGE_READ";
import Launch from "@/components/Launch";
import EMAIL_INPUT from "./components/screenTypes/EMAIL_INPUT";
import PHONE_NUMBER_INPUT from "./components/screenTypes/PHONE_NUMBER_INPUT";
import DATE_OF_BIRTH_SELECTION from "./components/screenTypes/DATE_OF_BIRTH_SELECTION";
import CONTACT_SELECT_SCREEN from "./components/screenTypes/CONTACT_SELECT_SCREEN";
import LANDING_PAGE from "./components/screenTypes/LANDING_PAGE.vue";


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            component: Layout,
            name: "layout",
            children: [
                get('/LANDING_PAGE', 'LANDING_PAGE', LANDING_PAGE, false, false),
                get('/START', 'START', START, true, true),
                get('/START_EID', 'START_EID', START_EID, false, false),
                get('/SELECT_AND_NAVIGATE', 'SELECT_AND_NAVIGATE', SELECT_AND_NAVIGATE, true),
                get('/INSTRUCTION', 'INSTRUCTION', INSTRUCTION, false),
                get('/SELECT_LANGUAGE', 'SELECT_LANGUAGE', SELECT_LANGUAGE, true),
                get('/EID_DISPLAY', 'EID_DISPLAY', EID_DISPLAY, true),
                get('/VISITOR_VALIDATION', 'VISITOR_VALIDATION', VISITOR_VALIDATION, true),
                get('/EID_READ', 'EID_READ', EID_READ),
                get('/DATE_OF_BIRTH_SELECT', 'DATE_OF_BIRTH_SELECT', DATE_OF_BIRTH_SELECTION),
                get('/INPUT_FORM', 'INPUT_FORM', INPUT_FORM,true,true),
                get('/INPUT_FORM_DISPLAY', 'INPUT_FORM_DISPLAY', INPUT_FORM_DISPLAY),
                get('/PRINT', 'PRINT', PRINT),
                get('/QR_DISPLAY', 'QR_DISPLAY', QR_DISPLAY),
                get('/QR_READ', 'QR_READ', QR_READ),
                get('/BADGE_READ', 'BADGE_READ', BADGE_READ),
                get('/EMAIL_INPUT', 'EMAIL_INPUT', EMAIL_INPUT,true,true),
                get('/PHONE_NUMBER_INPUT', 'PHONE_NUMBER_INPUT', PHONE_NUMBER_INPUT,true,true),
                get('/CONTACT_SELECT','CONTACT_SELECT',CONTACT_SELECT_SCREEN,true,true)
            ]
        },
        get('/launch/:error?', 'launch', Launch),
    ]
});

function get(path, name, component, header = false, footer = true) {
    return {
        path: path, name: name, components: {default: component}, meta: {header: header, footer: footer}
    };
}

export default router;