<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title text-color"> {{ popup }}</h1>
            </div>
            <div class="modal-body">
              <input type="button" class="btn blue-btn btn-success btn-xs" :value="popupButton"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import store from "@/store";
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";

const toTranslate = ['popupButton', 'popup'];

export default {
  name: "Popup",
  data() {
    return {
      popup: '',
      popupButton: ''
    }
  },
  computed: mapState(['toggleText']),
  watch: {
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id) {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, toTranslate);
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, toTranslate);
        }
      }
    }
  },
  mounted() {
    this.getTranslation(store.state.languageId, toTranslate);
  },
  methods: {
    getTranslation(lang = store.state.languageId, keys) {
      let app = this;
      if (lang) {
        keys.forEach(function (key) {
          screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
            app[key] = response.data;
          }).catch(function () {
            console.log("No translation found for fe." + key + " in language " + lang);
            app[key] = '-'
          })
        })
      }
    },
  }

}
</script>