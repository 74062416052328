<template>
  <router-view/>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
@import "assets/css/style.css";
@import "assets/css/styleLandscape.css";
@import "assets/css/popup.css";
@import "assets/css/w3.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
</style>
