<template>
  <div class="welcome">
    <span v-html="showWelcomeContent"></span>
  </div>

  <!--BUTTONS-->
  <div class="buttons" id="start-buttons">
    <button class="btn" v-for="button in buttons" :class="{'many-buttons' : buttons.length > 4}" :key="button.id"
            @click="goToStartScreen()" :disabled="disableButtons">
      <span v-html="button.text" :class="button.icon? 'w-9' : ''"></span>
      <i class='fas right primary-color move w3-margin-left' v-if="button.icon" :class="button.icon"/>
    </button>
  </div>
</template>

<script>
import store from "@/store";
import screenApiService from "@/services/screenApiService";

export default {
  name: 'LandingPage',
  data() {
    return {
      buttons: [],
    }
  },
  computed: {
    showWelcomeContent() {
      return this.$store.state.siteTheme.landingContent
    },
    disableButtons() {
      return this.$store.state.disableButtons
    }
  },
  mounted() {
    store.commit('showModal', false);
    this.screenProperties = store.state.currentScreenProperties;
    this.buttons = store.state.currentScreenOptions;
    if (document.readyState !== 'loading') {
      setTimeout(this.addHandAnimation, 500);
    } else {
      document.addEventListener('DOMContentLoaded', function () {
        setTimeout(this.addHandAnimation, 500);
      });
    }
  },
  methods: {
    addHandAnimation() {
      let startIcon = document.getElementsByClassName('start-button');
      const elem = document.createElement('i');
      if (startIcon && startIcon.length > 0) {
        let parentButton = startIcon[0].parentElement;
        elem.classList.add("fa", "fa-hand-point-right", 'fa-3x', 'secondary-color', "hand-pointer-start");
        if (parentButton)
          parentButton.prepend(elem)
      }
    },
    goToStartScreen() {
      store.state.disableButtons = true;
      screenApiService.getStartScreen();
    }
  }
}
</script>
