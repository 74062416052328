<template>
  <div class="instruction">
    <div v-if="visitorInput.length > 0">
      <div v-for="key in visitorInput" :key="key" class="validation-block" :id="'input_' + key">

        <numeric-form v-if="numericFields.includes(key)" :text_field="key" :text_value="''" :type="'input'"
                      @dataSaved="updateData"></numeric-form>

        <date-form v-else-if="key==='DATE_OF_BIRTH'" :text_field="key" :text_value="''" :type="'input'"
                   @dataSaved="updateData"></date-form>

        <text-form v-else :text_field="key" :text_value="''" :type="'input'"
                   @dataSaved="updateData"></text-form>

      </div>
    </div>

    <!--		<span v-if="currentField === Object.entries(fields).length">{{ thanksForInfo }}</span>-->
  </div>

  <div class="w3-hide">
    <QppButton :loaded="currentField === Object.entries(visitorInput).length"
               :buttons="currentScreenOptions"></QppButton>
  </div>
</template>

<script>
import QppButton from "@/components/common/QppButton";
import {mapState} from "vuex";
import store from "@/store";
import screenApiService from "@/services/screenApiService";
import TextForm from "@/components/views/TextForm";
import NumericForm from "@/components/views/NumericForm";
import DateForm from "@/components/views/DateForm";

const toTranslate = ['thanksForInfo'];

export default {
  name: 'VISITOR_INPUT',
  components: {QppButton, TextForm, NumericForm, DateForm},
  computed:
      mapState(['visitor', 'currentScreenOptions', 'toggleText'])
  ,
  data() {
    return {
      screenProperties: {},
      buttons: [],
      thanksForInfo: '',
      fields: {},
      visitorInput: [],
      currentField: 0,
      numericFields: ['phone', 'telephone', 'gsm'],
      loaded: false
    }
  },
  watch: {
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id) {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, toTranslate);
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, toTranslate);
        }
      }
    }
  },
  mounted() {
    this.buttons = store.state.currentScreenOptions;
    this.screenProperties = store.state.currentScreenProperties;
    this.getTranslation(store.state.languageId, toTranslate);
    this.getPayload();
    screenApiService.showPopup();
  },
  beforeUnmount() {
    screenApiService.resetPopup();
  },
  methods: {
    getPayload() {
      if (Object.prototype.hasOwnProperty.call(this.buttons[0], "defaultPayload")) {
        let defaultPayload = JSON.parse(this.buttons[0]['defaultPayload']);
        this.visitorInput = Object.hasOwn(defaultPayload, 'visitorInput') ? JSON.parse(defaultPayload.visitorInput) : [];
      }
    },
    updateData(data) {
      this.fields[data.field] = data.value;
      document.getElementById('input_' + data.field).style.display = 'none';
      this.currentField++;

      if (this.currentField < this.visitorInput.length) {
        let field = this.visitorInput[this.currentField];
        document.getElementById('input_' + field).style.display = 'block';
      }

      store.commit('setVisitorData', this.fields);
    },
    getTranslation(lang = store.state.languageId, keys) {
      let app = this;
      if (lang) {
        keys.forEach(function (key) {
          screenApiService.getTranslation(lang, 'fe.' + key).then(function (response) {
            app[key] = response.data;
          }).catch(function () {
            console.log("No translation found for fe." + key + " in language " + lang);
            app[key] = '-'
          })
        })
      }
    }
  }
}
</script>
<style scoped>
.validation-block {
  display: none;
}

.validation-block:first-child {
  display: block;
}
</style>
