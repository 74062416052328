<template>
  <div v-if="!isLoaded">
    <i class="fa-3x fas fa-spinner fa-pulse"></i>
  </div>

  <div v-else>
    <div class="instruction">
      <span class="heading w3-margin-bottom">
        <i class="fas fa-info-circle"></i>  {{ fieldName }}
      </span>
    </div>

    <div class="manual-entry w3-margin">
      <div class="w3-margin-top w3-margin-bottom">
        <div v-for="field in fields" :key="field.id" class="input-field">
          <label :for="field.id">{{ getFieldLabel(field.id) }}</label>
          <input
              :id="field.id"
              class="input w3-right"
              :class="{ 'error-field': invalidFieldInput[field.id] }"
              @input="onInputChange"
              @focusin="onFieldFocus"
              :placeholder="field.placeholder"
              autocomplete="off"
              type="number"
              v-model="fieldInput[field.id]"
              :min="field.min"
              :max="field.max"
          />
        </div>
      </div>

      <div v-if="checkAnyInvalidFields()" class="error-text">{{ invalidValueInfo }}</div>

      <SimpleKeyboard customLayout="numeric" @onChange="onChange"
                      :input="String(fieldInput[currentInputField])"
                      :keyboardClass="text_field+'-keyboard'" :disableButtons="disableButtons"/>
    </div>
    <div class="buttons">
      <button class="btn validation-form-submit" @click="submitForm()">{{ submit }}</button>
      <button v-if="skippable" class="btn validation-form-submit" @click="skipForm()">{{ skip }}</button>
    </div>
  </div>
</template>

<script>

import store from "@/store";
import SimpleKeyboard from "@/components/common/SimpleKeyboard";
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";

const toTranslate = ['invalidValueInfo', 'submit', 'fieldName', 'skip']

export default {
  name: "DATE_FORM",
  components: {SimpleKeyboard},
  props: {
    'text_field': undefined, 'text_value': undefined, 'type': {
      required: false
    }
  },
  emits: ['dataSaved'],
  data() {
    return {
      screenProperties: {},
      buttons: [],
      disableButtons: ['+'],
      fieldInput: {
        'day': '',
        'month': '',
        'year': ''
      },
      invalidFieldInput: {
        'day': null,
        'month': null,
        'year': null
      },
      inputSaved: false,
      fieldName: '',
      isLoaded: false,
      skip: 'Skip',
      invalidValueInfo: 'Gelieve geldige gegevens in te geven',
      submit: '',
      minYear: 1900, // minimum year
      maxYear: new Date().getFullYear(),
      currentInputField: "day",
      day: 'dag',
      month: 'maand',
      year: 'jaar',
      fields: [
        {id: "day", label: "day", min: 1, max: 31, placeholder: "--"},
        {id: "month", label: "month", min: 1, max: 12, placeholder: "--"},
        {id: "year", label: "year", min: 1900, max: new Date().getFullYear(), placeholder: "----"},
      ],
      monthMap: {
        '01': 'JAN',
        '02': 'FEB',
        '03': 'MAR',
        '04': 'APR',
        '05': 'MAY',
        '06': 'JUN',
        '07': 'JUL',
        '08': 'AUG',
        '09': 'SEP',
        '10': 'OCT',
        '11': 'NOV',
        '12': 'DEC',
      }
    }
  },
  computed: {
    skippable() {
      if (this.type === 'input') {
        return false;
      }

      const allowSkipValidation = this.siteTheme.allowSkipValidation;

      const skipSettings = allowSkipValidation ? allowSkipValidation.toLowerCase() !== 'false' : true;

      return skipSettings;
    },

    ...mapState(['toggleText', 'siteTheme'])
  },
  watch: {
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id) {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, toTranslate);
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, toTranslate);
        }
      }
    }
  },
  mounted() {
    this.buttons = store.state.currentScreenOptions;
    this.screenProperties = store.state.currentScreenProperties;
    this.getTranslation(store.state.languageId, toTranslate);
    this.getTranslation(store.state.languageId, Object.keys(this.invalidFieldInput));
    this.isLoaded = true;
    this.fieldName = this.text_field;
    if(this.text_value) this.setDateFields(); // when it's used for validation instead of input
  },
  methods: {
    setDateFields() {
      const [day, month, year] = this.text_value.split('/');

      if (day && month && year) {
        this.fieldInput.day = day;
        this.fieldInput.month = month;
        this.fieldInput.year = year;

        this.validateInput();
      } else {
        console.error('Invalid date format. Expected format: DD/MM/YYYY');
      }
    },
    getFieldLabel(fieldId) {
      switch (fieldId) {
        case "day":
          return this.day;
        case "month":
          return this.month;
        case "year":
          return this.year;
        default:
          return "";
      }
    },

    getTranslation(lang = store.state.languageId, keys) {
      if (!lang) return;
      keys.forEach((key) => {
        screenService
            .getTranslation(lang, `fe.${key === "fieldName" ? this.text_field : key}`)
            .then((response) => {
              this[key] = response.data || "-";
            })
            .catch(() => {
              console.log(`No translation found for fe.${key} in language ${lang}`);
              this[key] = "-";
            });
      });
    },
    onChange(input) {
      if (!input.includes('+')) {
        this.fieldInput[this.currentInputField] = Number(input);
        this.validateInput();
      }
    },
    onFieldFocus(event) {
      this.currentInputField = event.target.id;
    },
    onInputChange(input) {
      this.currentInputField = input.target.id;
      this.fieldInput[input.target.id] = Number(input.target.value);
      this.validateInput()
    },
    submitForm() {
      if (Object.values(this.invalidFieldInput).some(value => value === null)) {
        Object.keys(this.invalidFieldInput).forEach(key => {
          if (this.invalidFieldInput[key] === null) {
            this.invalidFieldInput[key] = true;
          }
        });
      } else if (!this.checkAnyInvalidFields()) {

        let inputDate = new Date(this.fieldInput.year, this.fieldInput['month'] - 1, this.fieldInput['day']);
        let today = new Date();

        // if DOB is more than today then throw error
        if (today < inputDate) {
          for (let key in this.invalidFieldInput) {
              this.invalidFieldInput[key] = true;
          }
          return
        }

        const day = String(this.fieldInput.day).padStart(2, '0');
        const month = this.monthMap[String(this.fieldInput.month).padStart(2, '0')];
        const year = this.fieldInput.year;

        const finalDate = `${day} ${month}  ${year}`;

        this.inputSaved = true;
        this.$emit('dataSaved', {'field': this.text_field, 'value': finalDate});
      }
    },
    skipForm() {
      console.log("skip form called");
      this.inputSaved = true;
      this.$emit('dataSaved', {'field': this.text_field, 'value': this.text_value})
    },

    checkAnyInvalidFields() {
      return Object.values(this.invalidFieldInput).some(value => value === true);
    },

    validateInput() {
      Object.keys(this.fieldInput).forEach((field) => {
        const min = this.fields.find((f) => f.id === field).min;
        const max = this.fields.find((f) => f.id === field).max;
        this.invalidFieldInput[field] = !this.validateInputField(this.fieldInput[field], min, max);
      });
    },
    validateInputField(input, min, max) {
      return !isNaN(input) && input >= min && input <= max;
    },
  }
}
</script>

<style scoped>
.manual-entry .input-field {
  display: flex;
  width: 100%;
  margin: 2vh 0;
}

.manual-entry .input-field * {
  flex: 1;
}

.manual-entry .input-field input {
  height: 6vh;
  letter-spacing: 3px;
  border-radius: 10px;
  background: var(--input-field-background-color);
  color: var(--secondary-color);
  padding: 20px;
  font-family: "customFont", var(--font-fallback);
  font-size: 3vh;
  box-shadow: 0 0 20px rgba(255, 255, 255, 1);
  animation: glow 800ms ease-out infinite alternate;
  outline: none;
  caret-color: black;
}

.manual-entry .input-field input:focus {
  background: var(--primary-color);
  border-color: var(--secondary-color);
  border-width: 5px;
  font-weight: bold;
  animation: glow 800ms ease-out infinite alternate;
}

.error-field {
  border-color: red;
  background-color: rgba(255, 0, 0, 0.35) !important;
}

/* Hide the spinner (up and down arrows) in Chrome, Safari, and Edge */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spinner (up and down arrows) in Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>
