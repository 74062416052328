<template>
  <transition name="modal">
    <div class="modal-mask" id="error-modal">
      <div class="modal-wrapper">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title warning-orange"> {{ popupCardWarning }}</h2>
            </div>
            <div class="modal-body">
              <input type="button" class="btn blue-btn btn-success btn-xs" :value="popupWarningButton" @click="close"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import store from "@/store";
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";

const toTranslate = ['popupCardWarning', 'popupWarningButton'];

export default {
  name: "CardWarning",
  data() {
    return {
      popupCardWarning: '',
      popupWarningButton: ''
    }
  },
  computed: mapState(['toggleText']),
  watch: {
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id) {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, toTranslate);
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, toTranslate);
        }
      }
    }
  },
  emits: ['closeWarning'],
  mounted() {
    this.getTranslation(store.state.languageId, toTranslate);
  },
  methods: {
    getTranslation(lang = store.state.languageId, keys) {
      let app = this;
      if (lang) {
        keys.forEach(function (key) {
          screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
            app[key] = response.data;
          }).catch(function () {
            console.log("No translation found for fe." + key + " in language " + lang);
            app[key] = '-'
          })
        })
      }
    },
    close() {
      this.$emit('closeWarning')
    }
  }

}
</script>