<template>
	<div class="instruction">
		<div v-if="Object.keys(fields).length > 0">
			<div v-for="(value, key) in fields" :key="key" class="validation-block" :id="'validate_' + key">

				<numeric-form v-if="numericFields.includes(key)" :text_field="key" :text_value="value"
				              @dataSaved="updateData"></numeric-form>

				<date-form v-else-if="key==='dob'" :text_field="key" :text_value="value" @dataSaved="updateData"></date-form>

				<text-form v-else :text_field="key" :text_value="value" @dataSaved="updateData"></text-form>

			</div>
		</div>

		<!--		<span v-if="currentField === Object.entries(fields).length">{{ fields }}{{ thanksForInfo }}</span>-->
	</div>

	<div class="w3-hide">
		<QppButton :loaded="currentField === Object.entries(fields).length" :updateKvsFields="originalFields"
		           :buttons="currentScreenOptions"></QppButton>
	</div>
</template>

<script>
import QppButton from "@/components/common/QppButton";
import TextForm from "@/components/views/TextForm";
import {mapState} from "vuex";
import store from "@/store";
import screenApiService from "@/services/screenApiService";
import NumericForm from "@/components/views/NumericForm";
import DateForm from "@/components/views/DateForm";

const toTranslate = ['thanksForInfo'];

export default {
	name: 'VISITOR_VALIDATION',
	components: {QppButton, TextForm, NumericForm, DateForm},
	computed:
			mapState(['visitor', 'currentScreenOptions', 'toggleText'])
	,
	data() {
		return {
			screenProperties: {},
			buttons: [],
			thanksForInfo: '',
			fields: {},
			originalFields: {},
			currentField: undefined,
      numericFields: ['phone', 'telephone', 'gsm']
		}
	},
	watch: {
		toggleText(newValue) {
			if (store.state.languageId !== store.state.defaultLanguage.id) {
				if (newValue === true) {
					this.getTranslation(store.state.defaultLanguage.id, toTranslate);
				} else if (newValue === false) {
					this.getTranslation(store.state.languageId, toTranslate);
				}
			}
		}
	},
	mounted() {
		this.getKvsData();
		this.buttons = store.state.currentScreenOptions;
		this.screenProperties = store.state.currentScreenProperties;
		this.getTranslation(store.state.languageId, toTranslate);
		screenApiService.showPopup();
	},
	beforeUnmount() {
		screenApiService.resetPopup();
	},
	methods: {
		getKvsData() {
			let app = this;
			screenApiService.getKvsData().then(function (response) {
				app.originalFields = response.data
				const propKey = '@class'
				const {[propKey]: propValue, ...fields} = response.data;
				console.log(propKey, propValue)
				app.fields = fields;

				app.currentField = 0;
			}).catch(function (err) {
				console.log(err)
			});
		},
		updateData(data) {
			this.originalFields[data.field] = data.value;
			document.getElementById('validate_' + data.field).style.display = 'none';
			this.currentField++;

			if (this.currentField < Object.entries(this.fields).length) {
				let field = Object.entries(this.fields)[this.currentField] // returns the property and value
				document.getElementById('validate_' + field[0]).style.display = 'block'
			}
		},
		getTranslation(lang = store.state.languageId, keys) {
			let app = this;
			if (lang) {
				keys.forEach(function (key) {
					screenApiService.getTranslation(lang, 'fe.' + key).then(function (response) {
						app[key] = response.data;
					}).catch(function () {
						console.log("No translation found for fe." + key + " in language " + lang);
						app[key] = '-'
					})
				})
			}
		}
	}
}
</script>
<style scoped>
.validation-block {
	display: none;
}

.validation-block:first-child {
	display: block;
}
</style>
