<template>
  <button class="btn" v-for="button in buttons" :class="btns" :key="button.id"
          @click="goToNextScreen(button)"
          :disabled="disableButtons">
    <span v-if="toggleText" v-html="button.helpText" :class="button.icon? 'w-9' : ''"></span>
    <span v-else v-html="button.text" :class="button.icon? 'w-9' : ''"></span>
    <i class='fas right primary-color move w3-margin-left' v-if="button.icon" :class="button.icon"/>
  </button>
</template>

<script>
import screenApiService from "@/services/screenApiService";
import {mapState} from "vuex";
import store from "@/store";

export default {
  name: "Button",
  props: {'buttons': undefined, 'loaded': undefined, 'updateKvsFields': undefined},
  emits: ["callback"],
  data() {
    return {
      selectedButton: undefined
    }
  },
  computed: {
    btns(){
        if (this.buttons.length > 4 && this.buttons.length <= 8) {
            return 'plus-4-buttons'
        }
        if (this.buttons.length > 8 && this.buttons.length <= 12) {
            return 'plus-8-buttons'
        }
        if (this.buttons.length > 12) {
            return 'plus-12-buttons'
        } else {
            return ''
        }
    },
    ...mapState(['toggleText', 'disableButtons']),
  },
  watch: {
    loaded(newValue) {
      if (newValue === true) {
        this.goToNextScreen(this.buttons[0]);
      }
    }
  },
  methods: {
    goToNextScreen(button) {
      store.state.disableButtons = true;
      if (this.updateKvsFields && Object.keys(this.updateKvsFields).length > 0) {
        console.log(this.updateKvsFields);
        screenApiService.setKvsData(this.updateKvsFields);
      }

      if (this.loaded === undefined || this.loaded === true) {
        let langId = store.state.defaultLanguage.id;
        let domaintypeId = null;
        let checkEer;
        let checkEid;
        let startNow = false;
        let screenshot = {
          selectedButton: button,
          flowOptions: store.state.currentScreenOptions,
          screenProperties: store.state.currentScreenProperties,
          instructions: store.state.currentScreenInstructions,
          languageId: store.state.languageId,
          domaintypeId: store.state.domaintypeId
        };

        if (Object.prototype.hasOwnProperty.call(button, "defaultPayload")) {
          let defaultPayload = JSON.parse(button['defaultPayload']);
          domaintypeId = defaultPayload && defaultPayload['domaintypeId'] ? defaultPayload['domaintypeId'] : store.state.domaintypeId;
          langId = defaultPayload && defaultPayload['languageId'] ? defaultPayload['languageId'] : store.state.languageId;
          checkEer = defaultPayload && defaultPayload['checkEer'] ? defaultPayload['checkEer'] : null;
          checkEid = defaultPayload && defaultPayload['checkEid'] ? defaultPayload['checkEid'] : null;
          store.commit('setDomain', domaintypeId);
          store.commit('selectLanguage', langId);
          screenshot.languageId = langId;
          screenshot.domaintypeId = domaintypeId;
        }

        if (checkEid !== null) {
          screenshot['checkEid'] = checkEid
        }

        if (checkEer !== null) {
          screenshot['checkEer'] = checkEer
        }

        if (this.$route.name === 'START') {
          startNow = true;
        }
        screenApiService.goToNextScreen(screenshot, startNow);
      } else {
        this.$emit('callback');
        this.selectedButton = button;
      }
    }
  }
}
</script>