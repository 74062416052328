<template>
  <div>
    <h1>Please enter your date of birth</h1>
    <div class="w3-row">
      <select v-model="selectedDay">
        <option disabled selected value style="display:none"> dag</option>
        <option v-for="(day,index) in days" :key="index">
          {{ day }}
        </option>
      </select>
      <select v-model="selectedMonth">
        <option disabled selected value style="display:none"> maand</option>
        <option v-for="(month,index) in months" :key="index" class="header">
          {{ month }}
        </option>
      </select>
      <select v-model="selectedYear">
        <option disabled selected value style="display:none"> jaar</option>
        <option v-for="(year,index) in years" :key="index">
          {{ year }}
        </option>
      </select>
    </div>
    <div class="input-form-submit">
      <QppButton :buttons="buttons" @callback="submit()" :loaded="inputSaved"></QppButton>
    </div>
  </div>
</template>

<script>
import QppButton from "@/components/common/QppButton";
import store from "@/store";
import screenApiService from "@/services/screenApiService";

export default {
  name: "DATE_OF_BIRTH_SELECTION.vue",
  components: {QppButton},
  data() {
    return {
      days: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
      months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      years: [2000, 2001, 2002, 2003, 2004, 2005, 2005],
      selectedYear: "",
      selectedDay: "",
      selectedMonth: "",

      screenProperties: {},
      buttons: [],
      input: "",
      inputSaved: false,
      names: '',
      visitor: undefined,
      isLoaded: false
    }
  },
  mounted() {
    let currentYear = new Date().getFullYear();
    this.years = Array.from({length: 100}, (x, i) => currentYear - i);

    this.buttons = store.state.currentScreenOptions;
    console.log(this.buttons);
    this.screenProperties = store.state.currentScreenProperties;
    this.visitor = store.state.visitor;
    screenApiService.showPopup();

    this.isLoaded = true;

  },
  methods: {
    submit() {
      console.log("submit is called")
      const app = this;
      let year = app.selectedYear;
      let day = app.selectedDay;
      let month = app.selectedMonth;
      if (year && day && month) {
        console.log("successfull");
        console.log(app.selectedYear);
        console.log(app.selectedDay);
        console.log(app.selectedMonth);

        app.visitor = {
          DATE_OF_BIRTH: day+"/"+month+"/"+year
        }
        console.log(app.visitor)
        store.commit('setVisitorData', app.visitor);
        app.inputSaved = true;
      } else {
        console.log("failure");
        console.log(app.selectedYear);
        console.log(app.selectedDay);
        console.log(app.selectedMonth);
      }


    }
  }
}
</script>

<style scoped>
select {
  width: 25%;
  height: 60px;
  font-size: 25px;
  margin: 5px;
  border-radius: 10px;
}

option {
  position: fixed;
  left: 30% !important;
  top: 0 !important;
}

</style>