<template>
  <div>
    <div class="welcome" v-if="currentScreenOptions.length===1">
      <DisplayText :screenProperties="currentScreenProperties"></DisplayText>
    </div>
    <div class="buttons" :class="currentScreenOptions.length===1 ? 'single-button' : ''">
      <QppButton :buttons="currentScreenOptions"></QppButton>
    </div>
  </div>
</template>

<script>
import QppButton from "@/components/common/QppButton";
import screenApiService from "@/services/screenApiService";
import {mapState} from "vuex";
import DisplayText from "@/components/common/DisplayText";

export default {
  name: "CONTACT_SELECT_SCREEN.vue",
  components: {QppButton, DisplayText},
  data() {
    return {}
  },
  computed: mapState(['currentScreenOptions', 'currentScreenProperties']),
  mounted() {
    screenApiService.showPopup();
  },
  beforeUnmount() {
    screenApiService.resetPopup();
  }
}
</script>

<style scoped>

</style>