<template>
  <div class="disabled-popup" v-if="disableButtons===true">
    <i class="fa-10x fas fa-spinner fa-pulse"></i>
  </div>

  <div class="content" v-bind:class="{footer_bar : !$route.meta.footer}">
    <!--Header-->
    <Header></Header>

    <!--Content-->
    <div class="outer">
      <div class="center middle">
        <div id="inner">
          <div v-if="showModal">
            <Popup @click="submitData"></Popup>
          </div>

          <div v-if="showError">
            <Error @closeError="closeError" @closeCardFailedError="closeCardFailedError"></Error>
          </div>

          <div v-if="showWarning">
            <Warning @closeWarning="closeWarning"></Warning>
          </div>

          <div v-if="showTimerPopup">
            <TimerPopup @closeTimerPopup="closeTimerPopup"></TimerPopup>
          </div>

          <router-view/>
        </div>
      </div>
    </div>
  </div>

  <!--Footer-->
  <Footer></Footer>
</template>

<script>
import Footer from "@/components/common/Footer";
import Header from "@/components/common/Header";
import Popup from "@/components/common/Popup";
import Error from "@/components/common/Error";
import Warning from "@/components/common/CardWarning";
import TimerPopup from "@/components/common/TimerPopup";
import store from "@/store";
import {mapState} from "vuex";
import screenApiService from "@/services/screenApiService";


export default {
  name: "Layout",
  components: {Footer, Header, Popup, Error, Warning, TimerPopup},
  mounted() {
    this.getScreen();
  },
  computed: mapState(['showModal', 'showError', 'showWarning', 'disableButtons', 'showTimerPopup']),
  methods: {
    getScreen() {
      screenApiService.getLandingPage();
    },
    submitData() {
      store.commit('showModal', false);
    },
    closeError() {
      store.commit('showError', false);
      if (store.state.errorStatus === 500) {
        console.log("Error 500: redirecting to homepage");
        screenApiService.getLandingPage();
      }
    },
    closeCardFailedError() {
      store.commit('showError', false);
      this.goBack();
    },
    closeWarning() {
      store.commit('showWarning', false);
    },
    closeTimerPopup() {
      store.commit('showTimerPopup', false);
      store.commit('setTimerPopupMessage', '');
    },
    goBack() {
      let screenshot = {
        selectedButton: {'id': 'scan_fail_redirect'},
        flowOptions: store.state.currentScreenOptions,
        screenProperties: store.state.currentScreenProperties,
        instructions: store.state.currentScreenInstructions,
        languageId: store.state.languageId,
        domaintypeId: store.state.domaintypeId,
      };
      screenApiService.goToPreviousScreen(screenshot);
    },
  }
}
</script>