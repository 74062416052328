<template>
  <!--INSTRUCTION-->
  <div class="instruction">
    <!-- TO DO : Change icon to image with URL provided from BE-->
    <i class="fa fa-10x fa-location-arrow" title="Richting"/><br><br>
    <DisplayText :screenProperties="screenProperties"></DisplayText>
  </div>

  <!-- TO DO : Button text to be provided from BE-->
  <button class="btn pointer" @click="closeInstruction({'id':'closeInstruction'})">Afsluiten</button>
</template>

<script>
import store from "@/store";
import screenService from "@/services/screenApiService";
import DisplayText from "@/components/common/DisplayText";
import screenApiService from "@/services/screenApiService";


export default {
  name: 'QR_DISPLAY',
  components: {DisplayText},
  data() {
    return {
      description: '',
      screenProperties: {}
    }
  },
  mounted() {
    this.screenProperties = store.state.currentScreenProperties;
    screenApiService.showPopup();
  },
  beforeUnmount() {
    screenApiService.resetPopup();
  },
  methods: {
    closeInstruction(button) {
      let screenshot = {
        selectedButton: button,
        flowOptions: store.state.currentScreenOptions,
        screenProperties: store.state.currentScreenProperties,
        languageId: store.state.languageId,
        domaintypeId: store.state.domaintypeId,
      };
      screenService.endFlow(screenshot);
    }
  }
}
</script>
