<template>
  <div v-if="!isLoaded">
    <i class="fa-3x fas fa-spinner fa-pulse"></i>
  </div>

  <div v-else>
    <div class="instruction">
      <DisplayText :screenProperties="screenProperties"></DisplayText>
    </div>

    <div class="manual-entry">
      <div class="input-field">
        <label for="manual_input">{{ names }}</label>
        <input :value="input"
               id="manual_input"
               class="input"
               @input="onInputChange"
               placeholder=""
               autocomplete="off"
               autofocus="autofocus"
               type="text" title="Name">
      </div>
      <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input"/>
    </div>
    <div class="input-form-submit">
      <QppButton :buttons="buttons" @callback="submit()" :loaded="inputSaved"></QppButton>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import DisplayText from "@/components/common/DisplayText";
import QppButton from "@/components/common/QppButton";
import screenApiService from "@/services/screenApiService";
import SimpleKeyboard from "@/components/common/SimpleKeyboard";
import screenService from "@/services/screenApiService";
import {mapState} from "vuex";

export default {
  name: "PHONE_NUMBER_INPUT",
  components: {DisplayText, QppButton, SimpleKeyboard},
  data() {
    return {
      screenProperties: {},
      buttons: [],
      input: "",
      inputSaved: false,
      names: '',
      visitor: undefined,
      isLoaded: false
    }
  },
  computed: mapState(['toggleText']),
  watch: {
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id) {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, 'names');
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, 'names');
        }
      }
    }
  },
  mounted() {
    this.buttons = store.state.currentScreenOptions;
    this.screenProperties = store.state.currentScreenProperties;
    this.getTranslation(store.state.languageId, 'names');
    this.visitor = store.state.visitor;
    screenApiService.showPopup();

    if (document.readyState !== 'loading') {
      setTimeout(() => {
        document.getElementById("manual_input").focus();
      }, 300);
    }
  },
  beforeUnmount() {
    screenApiService.resetPopup();
  },
  methods: {
    getTranslation(lang = store.state.languageId, key) {
      let app = this;
      if (lang) {
        screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
          app[key] = response.data;
        }).catch(function (er) {
          console.log("No translation found for fe." + key + " in language " + lang);
          console.log(er);
          app[key] = "-";
        })
      }
    },
    onChange(input) {
      this.input = input;
    },
    onKeyPress() {
      // console.log("button", button);
    },
    onInputChange(input) {
      this.input = input.target.value;
    },
    submit() {
      this.visitor = {
        phone: this.input
      }
      store.commit('setVisitorData', this.visitor);
      this.inputSaved = true;
    },
    validatePhoneNumber(){

    }
  }
}
</script>

<style scoped>

</style>