<template>
  <!--INSTRUCTION-->
  <div class="instruction">
    <div id="badge-scan" v-if="badgeScan">
      <DisplayText :screenProperties="screenProperties"></DisplayText>
      <div class="scan-media">
        <img :src="badgeReadImage" alt="scan" id="scan-image">
      </div>
    </div>

    <div id="badge-detail" v-if="badgeDetail">
      <span>{{ yourBadge }}</span>
      <span class="heading">{{ badgeNumber }}</span>
    </div>

    <div id="badge-wear" v-if="badgeWear">
      <span>{{ wearBadgeInfo }}</span>
      <div class="scan-media">
        <img :src="badgeWearImage" alt="scan" id="scan-image">
      </div>
    </div>
  </div>

  <div>
    <!--buttons for badge details info-->
    <div class="buttons" v-if="badgeDetail">
      <button class="btn buttons pointer close"
              @click="showWearInfo()">
        {{ yes }}
      </button>
      <button class="btn buttons pointer close"
              @click="readAgain()">
        {{ no }}
      </button>
    </div>

    <!--button from information flow to finish badge reading-->
    <QppButton :buttons="buttons" v-if="badgeWear"></QppButton>
  </div>

</template>

<script>
import store from "@/store";
import DisplayText from "@/components/common/DisplayText";
import QppButton from "@/components/common/QppButton";
import screenApiService from "@/services/screenApiService";
import {mapState} from "vuex";
import helper from "@/services/helper";

const toTranslate = ['yourBadge', 'wearBadgeInfo', 'yes', 'no'];

export default {
  name: 'BADGE_READ',
  components: {DisplayText, QppButton},
  data() {
    return {
      screenProperties: {},
      yourBadge: '',
      badgeNumber: '',
      wearBadgeInfo: '',
      yes: 'yes',
      no: 'no',
      readBadge: true,
      badgeScan: true,
      badgeDetail: false,
      badgeWear: false,
      buttons: [],
      badgeReadImage: null,
      badgeWearImage: null
    }
  },
  mounted() {
    this.buttons = store.state.currentScreenOptions;
    this.screenProperties = store.state.currentScreenProperties;
    this.checkInput();
    screenApiService.showPopup();
    this.getTranslation(store.state.languageId, toTranslate);
    let app = this;
    helper.findImage("badgeRead.gif").then(function (res) {
      app.badgeReadImage = res;
    })
    helper.findImage("badgeWear.gif").then(function (res) {
      app.badgeWearImage = res;
    })

    // for dividing screen in landscape mode
    document.getElementById('inner').classList.add('landscape-inner');
  },
  computed: mapState(['toggleText']),
  watch: {
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id) {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, toTranslate);
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, toTranslate);
        }
      }
    }
  },
  beforeUnmount() {
    screenApiService.resetPopup();
    document.getElementById('inner').classList.remove('landscape-inner');
  },
  methods: {
    async getBadgeNumber(epc) {
      let badgeNumber;
      if (localStorage.getItem(epc)) {
        badgeNumber = localStorage.getItem(epc)
      } else {
        await screenApiService.mapBadges(store.state.siteTheme.badgesListURL).then(function (res) {
          console.log("Badge mapping done: " + res)
        }).catch(function (er) {
          console.log("could not map badges")
          console.log(er)
        })

        await new Promise((resolve, reject) => setTimeout(() => {
          badgeNumber = localStorage.getItem(epc)
          if (badgeNumber) {
            console.log("epc found")
            resolve(badgeNumber);
          } else {
            console.log("no epc found")
            reject();
          }
        }, 1000));
      }

      return badgeNumber
    },
    checkInput() {
      let typedString = "";
      let app = this;

      document.addEventListener("keypress", function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
          // on ENTER
          e.preventDefault();
          if (app.readBadge === true) {
            app.scanBadge(typedString);
          }
          typedString = "";
        } else typedString += e.key;
      });
    },
    scanBadge(epc) {
      console.log("Scanned code: " + epc);
      this.getBadgeNumber(epc).then((res) => {
        console.log("Mapped badge number is: " + res)
        this.badgeNumber = res;
        this.badgeScan = false;
        this.badgeDetail = true;
        this.readBadge = false;
        store.commit('showError', false);
        store.state.errorStatus = '';
        document.getElementById('inner').classList.remove('landscape-inner');
      }).catch(function () {
        console.log('badge for following EPC  not found in badges.list: ', epc)
        store.commit('showError', true);
        store.state.errorStatus = 'BADGE_NOT_FOUND';
      })
    },
    showWearInfo() {
      // for dividing screen in landscape mode
      document.getElementById('inner').classList.add('landscape-inner');
      this.badgeScan = false;
      this.badgeDetail = false;
      this.badgeWear = true;
      store.commit('setBadgeData', this.badgeNumber);
    },
    readAgain() {
      // for dividing screen in landscape mode
      document.getElementById('inner').classList.add('landscape-inner');
      this.badgeScan = true;
      this.badgeDetail = false;
      this.badgeWear = false;
      this.readBadge = true;
    },
    getTranslation(lang = store.state.languageId, keys) {
      let app = this;
      if (lang) {
        keys.forEach(function (key) {
          screenApiService.getTranslation(lang, 'fe.' + key).then(function (response) {
            app[key] = response.data;
          }).catch(function () {
            console.log("No translation found for fe." + key + " in language " + lang);
            app[key] = '-'
          })
        })
      }
    }
  }
}
</script>
