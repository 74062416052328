<template>
  <div v-if="currentRouteMeta.footer === false">
    <img v-if="showLeftLogo" :src="leftLogoPath" class="footer-left-logo" alt="footer"/>
    <img v-if="showMainLogo" :src="mainLogoPath" class="footer-logo" alt="Logo"/>
  </div>
  <div class="footer-buttons" v-else>
    <div class="f-btn" v-if="hasBackButton()">
      <div class="home center">
        <i class="fa fa-5x fa-chevron-circle-left" title="Terug" @click="goBack({'id':'back'})"/><br>
        <span>{{ back }}</span>
      </div>
    </div>
    <div class="f-btn">
      <div class="center right">
        <i class="fa fa-5x fa-home" title="Home" @click="resetFlow({'id':'home'})"/><br>
        <span>{{ home }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import screenService from "@/services/screenApiService";
import store from "@/store";
import {mapState} from "vuex";

const partheaslogo = require("@/assets/images/PartheasLogo.png");
const toTranslate = ['home', 'back'];

export default {
  name: "Footer",
  computed: {
    currentRouteMeta() {
      return this.$route.meta;
    },
    ...mapState(['currentScreenProperties', 'languageId', 'siteTheme', 'toggleText'])
  },
  data() {
    return {
      back: '',
      home: '',
      leftLogoPath: '',
      showLeftLogo: false,
      mainLogoPath: '',
      showMainLogo: false,
    }
  },
  watch: {
    languageId(newValue) {
      this.getTranslation(newValue, toTranslate);
    },
    toggleText(newValue) {
      if (store.state.languageId !== store.state.defaultLanguage.id) {
        if (newValue === true) {
          this.getTranslation(store.state.defaultLanguage.id, toTranslate);
        } else if (newValue === false) {
          this.getTranslation(store.state.languageId, toTranslate);
        }
      }
    }
  },
  mounted() {
    if (this.languageId !== null) {
      this.getTranslation(this.languageId, toTranslate);
    }
    let mainLogo = this.getLogo(this.siteTheme.kioskMainLogo);
    let footerLogo = this.getLogo(this.siteTheme.kioskFooterLeftLogo);
    this.mainLogoPath = mainLogo.path;
    this.showMainLogo = mainLogo.show;
    this.leftLogoPath = footerLogo.path;
    this.showLeftLogo = footerLogo.show;
  },
  methods: {
    getLogo(siteThemeSettingURL) {
      let logo = {
        path: '',
        show: false
      };

      if (this.siteTheme && siteThemeSettingURL === '') {
        logo.show = false;
      } else if (this.siteTheme && siteThemeSettingURL === undefined) {
        logo.show = true;
        logo.path = partheaslogo;
      } else {
        logo.show = true;
        logo.path = siteThemeSettingURL;
      }
      return logo;
    },
    getTranslation(lang = store.state.languageId, keys) {
      let app = this;
      if (lang) {
        keys.forEach(function (key) {
          screenService.getTranslation(lang, 'fe.' + key).then(function (response) {
            app[key] = response.data;
          }).catch(function () {
            console.log("No translation found for fe." + key + " in language " + lang);
            app[key] = '-'
          })
        })
      }
    },
    goBack(button) {
      let screenshot = {
        selectedButton: button,
        flowOptions: store.state.currentScreenOptions,
        screenProperties: store.state.currentScreenProperties,
        instructions: store.state.currentScreenInstructions,
        languageId: store.state.languageId,
        domaintypeId: store.state.domaintypeId,
      };
      screenService.goToPreviousScreen(screenshot);
    },
    resetFlow(button) {
      let screenshot = {
        selectedButton: button,
        flowOptions: store.state.currentScreenOptions,
        screenProperties: store.state.currentScreenProperties,
        instructions: store.state.currentScreenInstructions,
        languageId: store.state.languageId,
        domaintypeId: store.state.domaintypeId,
      };
      screenService.resetFlow(screenshot);
    },
    hasBackButton() {
      return (this.currentScreenProperties.type !== 'INSTRUCTION' &&
          this.currentScreenProperties.type !== 'START');
    }
  }
}
</script>