<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
// import layoutFrench from "simple-keyboard-layouts/build/layouts/french";

export default {
  name: "SimpleKeyboard",
  props:
      {
        disableButtons: {
          required: false,
          type: Array
        },
        keyboardClass: {
          default: "simple-keyboard",
          type: String
        },
        input: {
          type: String
        },
        customLayout: {
          type: String
        },
      },
  data: () => ({
    keyboard: null,
  }),
  mounted() {
    let layout = this.customLayout === "numeric" ? {
      layout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "+ 0 {bksp}"]
      },
      display: {
        '{bksp}': "&#129068;"
      }
    } : {
      layout: {
        default: [
          "1 2 3 4 5 6 7 8 9 0 {bksp}",
          "a z e r t y u i o p",
          "q s d f g h j k l m",
          "{shift} w x c v b n",
          "@ .com {space} , .",
        ],
        shift: [
          "\" ' ( ) \u00E9 \u00F9 \u00E8 \u00E7 \u00E0 {bksp}",
          "A Z E R T Y U I O P",
          "Q S D F G H J K L M",
          "{shift} W X C V B N",
          "_ - {space} ? ;",
        ],
      },
      display: {
        '{bksp}': "&#129068;",
        '{shift}': 'shift',
        '{space}': ' '
      }
    };

    let customTheme = this.customLayout === "numeric" ? "hg-theme-default hg-layout-default partheas-theme numeric-theme simple-keyboard" : "hg-theme-default hg-layout-default partheas-theme simple-keyboard";

    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      theme: customTheme,
      ...layout,
      // buttonTheme: [
      //   {
      //     class: "hg-highlight",
      //     buttons: "{bksp}"
      //   }
      // ],
    });

    this.keyboard.setInput(this.input);

    if (this.disableButtons) {
      if (this.disableButtons.length > 0) {
        let el = document.querySelector('.' + this.keyboardClass);
        if (el) {
          this.disableButtons.forEach((val) => {
            let plusButton = el.querySelector(`[data-skbtn='${val}']`);
            if (plusButton) plusButton.classList.add('disabled-keyboard-button');
          })
        }
      }
    }

  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    }
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    }
  }
};
</script>


<style>
.simple-keyboard {
  color: var(--primary-color);
}

.simple-keyboard.hg-theme-default.partheas-theme {
  border: 3px solid var(--secondary-color);
  border-radius: 10px;
  font-family: "customFont", var(--font-fallback);
  /*background: rgba(45, 137, 204, 0.5);*/
}

.simple-keyboard.hg-layout-default .hg-button.hg-highlight {
  /*box-shadow: 0px 0px 10px 1px rgb(231, 97, 2);*/
  /*background: var(--primary-color);*/
  color: var(--secondary-color);
  /*z-index: 1;*/
}

.hg-theme-default .hg-button {
  height: 4.5vh !important;
  background: var(--text-color);
}

.hg-theme-default .hg-button span {
  font-size: 4vw;
  font-weight: bold;
}

.hg-theme-default .hg-button.hg-standardBtn {
  width: 20px;
}

.hg-theme-default .hg-button.hg-button-space {
  flex: 6;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".com"] {
  flex: 2 !important;
  max-width: 150px;
}

.simple-keyboard.hg-theme-default.partheas-theme .hg-button.hg-button-bksp {
  flex: 5;
}

.simple-keyboard.hg-theme-default.partheas-theme .hg-button.hg-button-bksp span {
  font-size: 8vw;
}

.numeric-theme.hg-theme-default.partheas-theme .hg-button.hg-button-bksp {
  flex: auto;
}

.numeric-theme .hg-button.hg-functionBtn {
  width: 20px;
}

.numeric-theme .hg-button.hg-functionBtn span {
  font-size: 9vw;
}

.numeric-theme .hg-button span {
  font-size: 5vw;
}
</style>
